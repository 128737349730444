let _apiEndpoint = "";

if (process.env.REACT_APP_ENV === "production") {
  _apiEndpoint =
    "https://i6vumwk0sc.execute-api.eu-west-1.amazonaws.com/production";
} else if (process.env.REACT_APP_ENV === "testing") {
  _apiEndpoint =
    "https://5hwq4bzfnh.execute-api.eu-west-1.amazonaws.com/testing";
}

export const apiEndpoint = _apiEndpoint;
