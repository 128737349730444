import PropTypes from "prop-types";
import React from "react";

import { Form, Formik } from "formik";

import FormGroup from "../../components/form/form-group";
import Label from "../../components/form/label";
import Input from "../../components/form/input";
import Select from "../../components/form/select";
import LoadingSpinner from "../../components/loading-spinner";
import translate from "../../translations";

import ValidatorService from "../../services/validator.service";

import styles from "./user-details-page.module.scss";

export default function UserDetailsForm(props) {
  const validators = new ValidatorService();
  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        job_title: "",
        country: props.country || "",
        email_address: "",
        confirm_email: "",
        telephone: "",
        acquisition_channel: props.source ? "Other" : "",
        acquisition_channel_other: props.source,
      }}
      onSubmit={(values) => {
        if (values.acquisition_channel === "Other") {
          values.acquisition_channel = values.acquisition_channel_other;
        }
        props.onSubmit(values);
      }}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <div className="row">
            <div className="col-12 col-lg-5">
              <FormGroup>
                <Label>{translate("TextFirstName")}*</Label>
                <Input name="first_name" validators={[validators.required]} />
              </FormGroup>

              <FormGroup>
                <Label>{translate("TextLastName")}*</Label>
                <Input name="last_name" validators={[validators.required]} />
              </FormGroup>

              <FormGroup>
                <Label>{translate("TextJobTitle")}*</Label>
                <Input name="job_title" validators={[validators.required]} />
              </FormGroup>

              <FormGroup>
                <Label>{translate("TextCompany")}*</Label>
                <Input validators={[validators.required]} name="company" />
              </FormGroup>

              <FormGroup>
                <Label>{translate("TextCountry")}*</Label>
                <Select name="country" validators={[validators.required]}>
                  <option value=""></option>
                  {props.countryList.map((country) => {
                    return <option key={country}>{country}</option>;
                  })}
                </Select>
              </FormGroup>

              <FormGroup>
                <Label>{translate("TextEmailAddress")}*</Label>
                <Input
                  name="email_address"
                  validators={[
                    validators.required,
                    validators.email,
                    (value) => {
                      return validators.sameAs(value, values.confirm_email);
                    },
                  ]}
                />
              </FormGroup>

              <FormGroup>
                <Label>{translate("TextConfirmEmail")}*</Label>
                <Input
                  name="confirm_email"
                  validators={[
                    validators.required,
                    validators.email,
                    (value) => {
                      return validators.sameAs(value, values.confirm_email);
                    },
                  ]}
                />
              </FormGroup>
            </div>

            <div className="col-12 offset-lg-2 col-lg-5">
              <FormGroup>
                <Label>{translate("TextTelephone")}*</Label>
                <Input
                  name="telephone"
                  validators={[validators.required, validators.telephone]}
                />
              </FormGroup>

              <FormGroup>
                <Label>{translate("UserDetailsAcquistionChannel")}</Label>
                <Select
                  disabled={props.source}
                  name="acquisition_channel"
                  validators={[]}
                >
                  <option value=""></option>
                  <option value="Search engine">
                    {translate("TextSearchEngine")}
                  </option>
                  <option value="Word of mouth">
                    {translate("TextWordOfMouth")}
                  </option>

                  <option value="Social media">
                    {translate("TextSocialMedia")}
                  </option>

                  <option value="Press or magazine">
                    {translate("TextPressOrMagazine")}
                  </option>

                  <option value="Worked with before">
                    {translate("TextWorkedWithBefore")}
                  </option>

                  <option value="Other">{translate("TextOther")}</option>
                </Select>
              </FormGroup>
              {values.acquisition_channel === "Other" && (
                <FormGroup>
                  <Label>{translate("TextPleaseSpecify")}*</Label>
                  <Input
                    name="acquisition_channel_other"
                    disabled={props.source}
                    validators={[validators.required]}
                  />
                </FormGroup>
              )}
              <div className={`mb-3 ${styles.disclaimer}`}>
                <p>{translate("UserDetailsContactConsentIntroduction")}</p>
                <p>
                  {translate("UserDetailsContactConsent")}&nbsp;
                  <a
                    style={{ color: "inherit" }}
                    href="mailto:hello@oliverwight.com"
                  >
                    hello@oliverwight.com
                  </a>
                  .
                </p>
                <p>
                  {translate("UserDetailsPersonalData")}&nbsp;
                  <a
                    style={{ color: "inherit" }}
                    href="https://www.oliverwight-eame.com/privacy-policy"
                  >
                    {translate("TextPrivacyPolicy")}
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>

          <div className="row g-0 mt-2">
            <div className="col-12 offset-lg-9 col-lg-3 d-flex align-items-center justify-content-end">
              {isSubmitting && (
                <span className="me-3">
                  <LoadingSpinner></LoadingSpinner>
                </span>
              )}

              <button
                disabled={isSubmitting}
                className="btn btn-primary"
                type="submit"
              >
                {translate("TextSendReport")}
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
UserDetailsForm.propTypes = {
  onSubmit: PropTypes.func,
  country: PropTypes.string,
  countryList: PropTypes.array,
  source: PropTypes.string,
};
