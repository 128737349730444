import React from "react";

import "./styles.scss";
// import appStyles from './app.module.scss'
import QuestionPage from "./pages/question-page";
import UserDetailsPage from "./pages/user-details-page/";
import ToolService from "./services/tool.service";
import CountryService from "./services/country.service";
import ResponseService from "./services/response.service";
import StartPage from "./pages/start-page";
import PageTitle from "./components/common/page-title/page-title";
import SummaryPage from "./pages/summary-page/";
import SuccessPage from "./pages/success-page";
import LoadingSpinner from "./components/loading-spinner";

import translate from "./translations";

export default class App extends React.Component {
  constructor() {
    super();

    this.state = {
      stage: "start",
      tool: {},
      answers: null,
      userDetails: null,
      startTime: null,
    };

    this.startSurvey = this.startSurvey.bind(this);
    this.setAnswers = this.setAnswers.bind(this);
    this.setStageQuestions = this.setStageQuestions.bind(this);
    this.setStageUserDetails = this.setStageUserDetails.bind(this);
    this.setUserDetailsAndSubmit = this.setUserDetailsAndSubmit.bind(this);
  }

  getToolId() {
    const variable = "tool";
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  }

  getSource() {
    const variable = "utm_source";
    const query = window.location.search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1]);
      }
    }
    return "";
  }

  componentDidMount() {
    this.ts = new ToolService();
    // this should just use the API instead of searching here
    const toolId = parseInt(this.getToolId(), 10);
    this.setState({ utmSource: this.getSource() });

    if (toolId) {
      this.ts
        .getDetail(toolId)
        .then((resp) => {
          if (!resp.ok) {
            throw new Error(resp.statusText);
          }
          return resp.json();
        })
        .then((tool) => {
          sessionStorage.setItem("toolLanguage", tool.language);
          this.setState({ tool: tool });
        })
        .catch(() => this.setState({ stage: "error" }));
    } else {
      this.setState({ stage: "error" });
    }
  }

  setAnswers(data) {
    this.setState({
      answers: data,
      stage: "summary",
    });
  }

  setStageQuestions() {
    this.setState({ stage: "questions" });
  }

  setStageUserDetails() {
    this.setState({ stage: "user-details" });
  }

  startSurvey() {
    this.cs = new CountryService();
    this.cs
      .whois()
      .then((resp) => resp.json())
      .then((location) => {
        this.setState({
          detectedCountry: location.detected_country,
          countries: location.countries,
        });
      });
    this.setStageQuestions();
    this.setState({
      startTime: Date.now(),
    });
  }

  setUserDetailsAndSubmit(userDetails) {
    const rs = new ResponseService(this.state.tool.id);
    this.setState({
      userDetails: userDetails,
    });

    const data = {
      ...userDetails,
      started_at: this.state.startTime,
      answers: this.state.answers.map((x) => {
        return {
          question_id: x.id,
          option_id: x.answerId,
        };
      }),
    };

    rs.submit(data)
      .then((resp) => {
        return resp.json();
      })
      .then((jsonResponse) => {
        this.setState({
          stage: "success",
          response: jsonResponse,
        });

        const analyticsData = {
          event: "triggerSASubmittedEvent",
          eventData: {
            contactConsent: userDetails.contact_consent,
          },
        };
        window.top.postMessage(analyticsData, "*");
      });
  }

  render() {
    if (this.state.tool.id) {
      const stages = {
        start: (
          <StartPage
            tool={this.state.tool}
            onStart={this.startSurvey}
          ></StartPage>
        ),
        questions: (
          <QuestionPage
            answers={this.state.answers}
            tool={this.state.tool}
            onSubmit={this.setAnswers}
          ></QuestionPage>
        ),
        summary: (
          <SummaryPage
            answers={this.state.answers}
            onNavigateBack={this.setStageQuestions}
            onSubmit={this.setStageUserDetails}
          ></SummaryPage>
        ),
        "user-details": (
          <UserDetailsPage
            country={this.state.detectedCountry}
            countryList={this.state.countries}
            source={this.state.utmSource}
            tool={this.state.tool}
            onSubmit={this.setUserDetailsAndSubmit}
          ></UserDetailsPage>
        ),
        success: <SuccessPage response={this.state.response}></SuccessPage>,
      };
      const page = stages[this.state.stage];

      return (
        <div className="container">
          <PageTitle>
            <div>{this.state.tool.name}</div>
            <div>{translate("TextSelfAssessmentTool")}</div>
          </PageTitle>
          <div className="row g-0">
            <div className="col-12 col-md-10 offset-md-1">{page}</div>
          </div>
        </div>
      );
    } else {
      if (this.state.stage === "error") {
        return <div>There has been a problem loading this tool</div>;
      } else {
        return (
          <div className={"row g-0"}>
            <div className="col-12 col-md-8 offset-md-2 text-primary text-center">
              <LoadingSpinner size="64px"></LoadingSpinner>
            </div>
          </div>
        );
      }
    }
  }
}
