import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage, Field } from "formik";

export default function Select(props) {
  return (
    <>
      <Field
        as="select"
        name={props.name}
        disabled={props.disabled}
        className="form-select form-select-lg"
        validate={(value) => {
          for (const validator of props.validators) {
            const validationResult = validator(value);
            if (validationResult !== true) {
              return validationResult;
            }
          }
          return null;
        }}
      >
        {props.children}
      </Field>
      <ErrorMessage className="text-danger" name={props.name} component="div" />
    </>
  );
}

Select.propTypes = {
  children: PropTypes.any,
  validators: PropTypes.array,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};
